/*@font-face {
  font-family: 'Mont';
  src: local('Mont'),
  url("fonts/Montserrat-Black.ttf") format('TrueType'),
  url("fonts/Montserrat-BlackItalic.ttf") format('TrueType'),
  url("fonts/Montserrat-Bold.ttf") format('TrueType'),
  url("fonts/Montserrat-BoldItalic.ttf") format('TrueType'),
  url("fonts/Montserrat-ExtraLight.ttf") format('TrueType'),
  url("fonts/Montserrat-ExtraLightItalic.ttf") format('TrueType'),
  url("fonts/Montserrat-ExtraBold.ttf") format('TrueType'),
  url("fonts/Montserrat-ExtraBoldItalic.ttf") format('TrueType'),
  url("fonts/Montserrat-LightItalic.ttf") format('TrueType'),
  url("fonts/Montserrat-Medium.ttf") format('TrueType'),
  url("fonts/Montserrat-MediumItalic.ttf") format('TrueType'),
  url("fonts/Montserrat-Regular.ttf") format('TrueType'),
  url("fonts/Montserrat-SemiBold.ttf") format('TrueType'),
  url("fonts/Montserrat-SemiBoldItalic.ttf") format('TrueType'),
  url("fonts/Montserrat-Thin.ttf") format('TrueType'),
  url("fonts/Montserrat-ThinItalic.ttf") format('TrueType'),
  url("fonts/Montserrat-Light.ttf") format('TrueType'),
}*/

/*
@font-face {
  font-family: 'Roboto';
  src: local('Roboto'),
  url("fonts/Roboto-Black.ttf") format('TrueType'),
  url('fonts/Roboto-BlackItalic.ttf') format('TrueType'),
  url("fonts/Roboto-Bold.ttf") format('TrueType'),
  url("fonts/Roboto-BoldItalic.ttf") format('TrueType'),
  url("fonts/Roboto-Italic.ttf") format('TrueType'),
  url("fonts/Roboto-Light.ttf") format('TrueType'),
  url("fonts/Roboto-LightItalic.ttf") format('TrueType'),
  url("fonts/Roboto-Medium.ttf") format('TrueType'),
  url("fonts/Roboto-MediumItalic.ttf") format('TrueType'),
  url("fonts/Roboto-Regular.ttf") format('TrueType'),
  url("fonts/Roboto-Thin.ttf") format('TrueType'),
  url("fonts/Roboto-ThinItalic.ttf") format('TrueType');
}
*/

@font-face {
  font-weight: 200;
  font-family: "Roboto";
  src: url("fonts/Roboto-Thin.ttf") format('TrueType');
}

@font-face {
  font-style: italic;
  font-weight: 200;
  font-family: "Roboto";
  src: url("fonts/Roboto-ThinItalic.ttf") format('TrueType');
}

@font-face {
  font-weight: 300;
  font-family: "Roboto";
  src: url("fonts/Roboto-Light.ttf") format('TrueType');
}

@font-face {
  font-style: italic;
  font-weight: 300;
  font-family: "Roboto";
  src: url("fonts/Roboto-LightItalic.ttf") format('TrueType');
}

@font-face {
  font-weight: normal;
  font-family: "Roboto";
  src: url("fonts/Roboto-Regular.ttf") format('TrueType');
}

@font-face {
  font-style: italic;
  font-weight: normal;
  font-family: "Roboto";
  src: url("fonts/Roboto-Italic.ttf") format('TrueType');
}

@font-face {
  font-weight: 500;
  font-family: "Roboto";
  src: url("fonts/Roboto-Medium.ttf") format('TrueType');
}

@font-face {
  font-weight: 500;
  font-style: italic;
  font-family: "Roboto";
  src: url("fonts/Roboto-MediumItalic.ttf") format('TrueType');
}

@font-face {
  font-weight: bold;
  font-family: "Roboto";
  src: url("fonts/Roboto-Bold.ttf") format('TrueType');
}

@font-face {
  font-weight: bold;
  font-style: italic;
  font-family: "Roboto";
  src: url("fonts/Roboto-BoldItalic.ttf") format('TrueType');
}


body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  width: 100vw;
  font-weight: 300;
  overflow-x: hidden;
}

a {
  text-decoration: none;
}
.App {
  overflow-y: hidden;
  /*margin-top: 60px;
  min-height: calc(100% - 100px);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  box-shadow: rgba(0, 0, 0, .38) 0px -2px 20px;
  margin-left: auto;
  margin-right: auto;*/
}