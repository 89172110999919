.tagsClass {
    display: flex;
    flex-direction: column;
}
.tagInputClass {
    display: flex;
    flex-direction: column;
}
.tagInputFieldClass {
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 20px;
    height: 39px;
    min-width: 20px;
    margin-right: 0;
    margin-left: 0;
    white-space: nowrap;
    width: 300px;
    border: solid 1px #272727;
    background: #272727;
    color: #FFFFFF;
    border-radius: 4px;
}
.tagInputFieldClass:hover,
.tagInputFieldClass:focus
{
    outline: none;
}
.selectedClass {
    display: flex;
}
.tagClass {
    padding-left: 16px;
    padding-right: 8px;
    min-height: 30px;
    border-radius: 4px;
    background: #9778d0;
    color: #FFFFFF;
    margin-top: 8px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    flex-direction: row;
    box-shadow: rgba(0, 0, 0, .28) 0 -4px 16px 0;
    transition: all .2s ease-in-out;
}
.tagClass:hover {
    transform: scale(1.03);
    transition: all .2s ease-in-out;
}
.removeClass {
    background: transparent;
    border: none;
    margin-left: 8px;
    font-size: 22px;
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;
}
.suggestionsClass {
    max-width: 340px;
    background: #272727;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, .28) 0 -4px 16px 0;
    color: #FFFFFF;
    z-index: 2;
    list-style-type: none;
}

.suggestionsClass ul {
    list-style-type: none;
}
.suggestionsClass > ul > li {
    cursor: pointer;
}
.activeSuggestionClass > span > mark {
    background: rgba(151, 120, 208, .55)!important;
    color: #FFFFFF!important;
}
.activeSuggestionClass ul {
    list-style-type: none;
}